import { createBrowserHistory } from "history";
import React from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "reactstrap";
import "./App.css";
import store from "./app/store";
import Footer from "./components/Footer";
import MainNavBar from "./components/Navbar";
import Routes from "./components/Routes";

ReactGA.initialize("G-G96C16QBJY");

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const menuItems = [
  { title: "About", link: "/about" },
  // {
  //   title: "Magic Formula",
  //   items: [
  //     { title: "Current", link: "/magic" },
  //     { title: "Historical", link: "/magic_historical" },
  //   ],
  // },
  // {
  //   title: "Growth",
  //   items: [{ title: "Annual", link: "/growth/annual" }],
  // },
  { title: "ContactUs", link: "/contact_us" },
];
function MainApp() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <Container>
            <MainNavBar items={menuItems} />
          </Container>
          <Routes />
        </Router>
      </Provider>
      {/* <Footer /> */}
    </React.StrictMode>
  );
}

export default MainApp;
