import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Carousel,
  CarouselIndicators,
  CarouselControl,
} from "reactstrap";
import { FUNDAMENTALS_URL } from "../app/endpoints";
import { sprintf } from "sprintf-js";
import { fetchData } from "../utils/fetch";
import classnames from "classnames";

function Tabs(props) {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { content, titles } = { ...props };
  return (
    <div>
      <Nav tabs>
        {titles.map((title, index) => (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === (index + 1).toString(),
              })}
              onClick={() => {
                toggle((index + 1).toString());
              }}
            >
              {title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {content.map((cont, index) => (
          <TabPane tabId={(index + 1).toString()}>{cont}</TabPane>
        ))}
      </TabContent>
    </div>
  );
}

export default function CompanyDetails(props) {
  const [data, updateData] = useState({ ok: null });
  const ticker = props.match.params.ticker;
  const titles = [
    "Quarterly Per Share (Income)",
    "TTM Per Share (Income)",
    "Quaterly Per Share(Balance)",
  ];
  const plots = [
    <div id="fund-income-q-plot" className="bk-root" />,
    <div id="fund-income-plot" className="bk-root" />,
    <div id="fund-balance-plot" className="bk-root" />,
  ];
  const plotContent = plots.map((plot, index) => (
    <Row>
      <Col>{plot}</Col>
    </Row>
  ));

  document.title = ticker + " | Stock NPV";
  useEffect(() => {
    fetchData(sprintf(FUNDAMENTALS_URL, ticker), (data) => {
      updateData(data);
      window.Bokeh.embed.embed_item(data.price_chart, "price-chart");
      window.Bokeh.embed.embed_item(data.fund_income_plot, "fund-income-plot");
      window.Bokeh.embed.embed_item(
        data.fund_income_q_plot,
        "fund-income-q-plot"
      );
      window.Bokeh.embed.embed_item(
        data.fund_balance_plot,
        "fund-balance-plot"
      );
      // window.Bokeh.embed.embed_item(data.income_vs_price_plot, "income-vs-price-plot");
    });
  }, []);

  if (data.ok === null) {
    return <div></div>;
  }
  if (!data.ok) {
    return <h2>No data available</h2>;
  }

  return (
    <div>
      <Container>
        <ul>
          <li>Ticker: {ticker}</li>
          <li>Sector: {data.profile.sector}</li>
          <li>Industry: {data.profile.industry}</li>
        </ul>
      </Container>
      <Container className="d-flex justify-content-center">
        <div id="price-chart" className="bk-root" />
      </Container>
      <Container className="d-flex justify-content-center">
        <div id="income-vs-price-plot" className="bk-root" />
      </Container>
      {/* This is annoying since it causes the screen to jump for firefox */}
      <Container className="d-flex justify-content-center">
        <Tabs titles={titles} content={plotContent} />
      </Container>
      <Container>
        <h2>Company Profile</h2>
        <p>{data.profile.description}</p>
      </Container>
    </div>
  );
}
