import React from "react";
import PropTypes from "prop-types";
import {
  FormEmail,
  FormText,
  FormHidden,
  FormPassword,
  FormTextArea,
  FormDate,
  FormSelect,
  FormSelectMulti,
  FormRadio,
  FormCheck,
  FormTinyMce,
} from "./FormElements";
import { Form, Button } from "reactstrap";

const formElements = {
  FormEmail,
  FormText,
  FormHidden,
  FormPassword,
  FormTextArea,
  FormDate,
  FormSelect,
  FormSelectMulti,
  FormRadio,
  FormCheck,
  FormTinyMce,
};



/** 
* DynamicForm function
* @id {string} - for id of the functon
* @disabled {bool} - if true sets the submit button to disabled
* @onSubmit {function} - a function to to handle the response from the server
* @updateFormHook {function} - post processing hook for the data before submitting
* @return {jsx} returns a form JSX.
*/
export default function DynamicForm(props) {
  const id = props.id;
  const disabled = props.disabled ? props.disabled : false;
  const handler = props.onSubmit ? props.onSubmit : (data) => {};
  const updateFormHook = props.updateFormHook
    ? props.updateFormHook
    : (form) => {};
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    updateFormHook(formData);

    fetch(props.url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => handler(data));
  };
  return (
    <Form id={id} onSubmit={(e) => onSubmit(e)}>
      {props.fields.map((field, index) => {
        const Tag = formElements[field.type];
        const key = parseInt(index) + `_${field.name}`;
        return <Tag {...field} key={key} />;
      })}
      <Button color="primary" disabled={disabled}>
        Submit
      </Button>
    </Form>
  );
}

DynamicForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  id: PropTypes.string,
  diabled: PropTypes.bool,
  updateFormHook: PropTypes.func
};
