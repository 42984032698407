import React from "react";
import { Route, Switch } from "react-router-dom";
import About from "../pages/About";
import Home from "../pages/Home";
import CompanyDetails from "../pages/CompanyDetails";
import Magic from "../pages/Magic";
import MagicHistorical from "../pages/MagicHistorical";
import GrowthAnnual from "../pages/GrowthAnnual";
import Login from "../pages/Login";
import Pricing from "../pages/Pricing";
import Register from "../pages/Register";
import SignUp from "../pages/SignUp";
import ContactUs from "../pages/ContactUs";

export default function () {
  return (
    <Switch>
      <Route path="/about" render={(props) => <About {...props} />} />
      <Route path="/register" render={(props) => <Register {...props} />} />
      <Route path="/company_details/:ticker" render={(props) => <CompanyDetails {...props} />} />
      {/* <Route path="/magic" exact render={(props) => <Magic {...props} />} />
      <Route path="/magic_historical" render={(props) => <MagicHistorical {...props} />} />
      <Route path="/growth/annual" render={(props) => <GrowthAnnual {...props} />} /> */}
      <Route path="/contact_us" render={(props) => <ContactUs {...props} />} />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route path="/signup" render={(props) => <SignUp {...props} />} />
      <Route path="/" exact render={(props) => <Home {...props} />} />
    </Switch>
  );
}
