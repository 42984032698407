import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  ADVANCERS_RETREATERS_URL,
  BUSINESS_DATE_URL,
  NUM_ADVANCERS_RETREATERS_URL,
  TOP_MOVERS_URL,
} from "../app/endpoints";
import DataTable from "../components/DataTable";
import Table, { renderLinkHelper } from "../components/Table";
import { fetchData } from "../utils/fetch";
import { sprintf } from "sprintf-js";

function AdvancersRetreaters() {
  const [advancersRetreaters, updateAdvancersRetreaters] = useState({
    advancers: 0,
    retreaters: 0,
  });

  useEffect(() => {
    fetchData(NUM_ADVANCERS_RETREATERS_URL, updateAdvancersRetreaters);
  }, []);

  return (
    <Container>
      <ul>
        <li>Advancers: {advancersRetreaters["advancers"]}</li>
        <li>retreaters: {advancersRetreaters["retreaters"]}</li>
      </ul>
    </Container>
  );
}

function TopMovers() {
  const [topMovers, updateTopMovers] = useState({
    advancers: [],
    retreaters: [],
  });
  useEffect(() => {
    fetchData(TOP_MOVERS_URL, updateTopMovers);
  }, []);

  const styles = { className: "table-sm" };
  const columnHandlers = {
    "Price Change": (column, row) => <td>{row[column] + "%"}</td>,
    Ticker: renderLinkHelper("/company_details/%s"),
  };
  const skipColumns = ["Industry", "Sector"]

  return (
    <Container>
      <Row>
        <Col>
          <h4>Advancers</h4>
          {topMovers["advancers"].length > 0 && (
            <Table
              data={topMovers["advancers"]}
              styles={styles}
              columnHandlers={columnHandlers}
              skipColumns={skipColumns}
            />
          )}
        </Col>
        <Col>
          <h4>Retreaters</h4>
          {topMovers["retreaters"].length > 0 && (
            <Table
              data={topMovers["retreaters"]}
              styles={styles}
              columnHandlers={columnHandlers}
              skipColumns={skipColumns}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

function BusinessDate() {
  const [busDate, updateBusDate] = useState("");

  useEffect(() => {
    fetchData(BUSINESS_DATE_URL, updateBusDate);
  }, []);

  return (
    <Container>
      <h5>Business Date: {busDate}</h5>
    </Container>
  );
}

function AdvancersRetreatersDetails() {
  const [data, upateData] = useState({ columns: [], data: [] });
  const colFormatters = {
    "Price Change": (value) => value.cell.value + "%",
    Ticker: (value) => (
      <a href={sprintf("/company_details/%s", value.cell.value)}>
        {value.cell.value}
      </a>
    ),
    // "Market Cap": (value) => (
    //   <div style={{ textAlign: "right" }}>{value.cell.value}</div>
    // ),
    "Market Cap": (value) => value.cell.value.toLocaleString(),
  };
  useEffect(() => {
    fetchData(ADVANCERS_RETREATERS_URL, upateData);
  }, []);

  return (
    <DataTable
      columns={data.columns}
      data={data.data}
      className="table-sm"
      colFormatters={colFormatters}
    />
  );
}
export default function () {
  document.title = "StockNPV"
  return (
    <div>
      <BusinessDate />
      <AdvancersRetreaters />
      <TopMovers />
      <Container>
        <hr />
      </Container>
      <AdvancersRetreatersDetails />
    </div>
  );
}
