import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import TinyMce from "./Tinymce";

export function FormInput(props) {
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <Input key={props.defaultValue}
        type={props.type}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder ? props.placeholder : props.name}
        required={props.required}
        value={props.value}
        defaultValue={props.defaultValue}
      />
    </FormGroup>
  );
}

export function FormEmail(props) {
  return <FormInput {...props} type="email" />;
}

export function FormHidden(props) {
  return (
    <Input
      type="hidden"
      name={props.name}
      id={props.name}
      placeholder={props.placeholder ? props.placeholder : props.name}
      required={props.required}
      value={props.value}
      defaultValue={props.defaultValue}
    />
  );
}

export function FormPassword(props) {
  return <FormInput {...props} type="password" />;
}

export function FormText(props) {
  return <FormInput {...props} type="text" />;
}

export function FormTextArea(props) {
  return <FormInput {...props} type="textarea" />;
}

export function FormDate(props) {
  return (
    <FormInput
      {...props}
      type="date"
      defaultValue={new Date().toISOString().slice(0, 10)}
    />
  );
}

export function FormSelectBase(props) {
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <Input
        type={props.type}
        name={props.name}
        id={props.name}
        multiple={props.multiple}
      >
        {props.options.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
}

export function FormSelect(props) {
  return <FormSelectBase {...props} type="select" />;
}

export function FormSelectMulti(props) {
  return <FormSelectBase {...props} type="select" multiple />;
}

export function FormRadio(props) {
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>

      {props.options.map((opt) => (
        <FormGroup check key={["group", props.name, opt.value].join("_")}>
          <Label check key={["label", props.name, opt.value].join("_")}>
            <Input
              type="radio"
              key={["value", props.name, opt.value].join("_")}
              value={opt.value}
              name={props.name}
            />{" "}
            {opt.label}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  );
}

export function FormCheck(props) {
  return (
    <FormGroup check>
      <Label check>
        <Input type="checkbox" name={props.name} id={props.name} />{" "}
        {props.label}
      </Label>
    </FormGroup>
  );
}

export function FormTinyMce(props) {
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <TinyMce
        id={props.name}
        textareaName={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
    </FormGroup>
  );
}

export function onSubmit(e) {
  e.preventDefault();
  var formData = new FormData(e.target);
  console.log(formData);
  console.log(e.target);
  console.log(e.target.name.value);
  console.log(e.target.mceComment.value);
  console.log(e.target.mceComment2.value);

  // for (var key in formData.keys()) {
  //   console.log(key);
  // }
  // for (var value in formData.values()) {
  //   console.log(value);
  // }
}
