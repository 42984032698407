import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLogout,
  selectUsername,
  selectAdmin,
} from "../features/auth/authSlice";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from "reactstrap";

function BlogNav(props) {
  const blogAdmin = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        Blog
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem>
          <NavLink tag={Link} to="/blog">
            View Blog
          </NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink tag={Link} to="/blog/listPosts">
            List Posts
          </NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink tag={Link} to="/blog/createPost">
            Create Post
          </NavLink>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
  const blogNormal = (
    <NavItem>
      <NavLink tag={Link} to="/blog">
        Blog
      </NavLink>
    </NavItem>
  );

  if (props.admin) {
    return blogAdmin;
  } else {
    return blogNormal;
  }
}

function Brand(props) {
  const { title, link, external } = props;

  if (!external) {
    return (
      <NavbarBrand tag={Link} to={link}>
        {title}
      </NavbarBrand>
    );
  } else {
    return <NavbarBrand href={link}>{title}</NavbarBrand>;
  }
}

function NavItem_(props) {
  const { title, link, external } = props;
  if (!external) {
    return (
      <NavItem>
        <NavLink tag={Link} to={link}>
          {title}
        </NavLink>
      </NavItem>
    );
  } else {
    return (
      <NavItem>
        <NavLink href={link}>{title}</NavLink>
      </NavItem>
    );
  }
}

function DropdownItem_(props) {
  const { title, link, external, divider } = props;
  if (divider) {
    return <DropdownItem divider />;
  }
  if (!external) {
    return (
      <DropdownItem tag={Link} to={link}>
        {title}
      </DropdownItem>
    );
  } else {
    return <DropdownItem href={link}>{title}</DropdownItem>;
  }
}

function Dropdown(props) {
  const { title, items } = props;
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        {title}
      </DropdownToggle>
      <DropdownMenu right>
        {items.map((item, index) => (
          <DropdownItem_ key={index} {...item} />
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function renderItems(item, index) {
  if ("items" in item) {
    return <Dropdown key={index} {...item} />;
  } else {
    return <NavItem_ key={index} {...item} />;
  }
}

function CreateNavItem(props) {
  return (
    <NavItem>
      <NavLink tag={Link} to={props.link}>
        {props.title}
      </NavLink>
    </NavItem>
  );
}

export default function MainNavBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const items = props.items;
  const username = useSelector(selectUsername);
  const admin = useSelector(selectAdmin);

  const logout = () => {
    const url = "/api/auth/logout";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          dispatch(setLogout());
        }
      });
  };
  const defaultNavs = [{ link: "/about", title: "About" }];
  const devNavs = [
    { link: "/plots", title: "Plots" },
    { link: "/pricing", title: "Pricing" },
  ];
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Navbar className="mb-5" color="light" light expand="md">
      <NavbarBrand tag={Link} to="/">
        StockNPV
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {items.map((item, index) => renderItems(item, index))}
          {admin && devNavs.map((nav) => <CreateNavItem {...nav} />)}
          {/* <BlogNav admin={admin} />
          <NavItem>
            <NavLink tag={Link} to="/plots">
              Plots
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/pricing">
              Pricing
            </NavLink>
          </NavItem> */}
        </Nav>
        {/* {!username ? (
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/login">
                Login
              </NavLink>
            </NavItem>
            <NavItem>
              <Button tag={Link} to="/signup" color="info">
                Sign Up
              </Button>
            </NavItem>
          </Nav>
        ) : (
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink>{username}</NavLink>
            </NavItem>
            <NavItem>
              <Button onClick={logout} color="info">
                Logout
              </Button>
            </NavItem>
          </Nav>
        )} */}
      </Collapse>
    </Navbar>
  );
}
