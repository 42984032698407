/**
 * Fetches data from some source and stores it in a store
 * @param {string} url : url to some resource
 * @param {function} store : function to store data in some store
 */
export function fetchData(url, store) {
  // Function to fetch data and perform a store

  fetch(url)
    .then((response) => response.json())
    .then((data) => store(data));
}
