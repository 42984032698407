import React from "react";
import { Container } from "reactstrap";
import "../styles/about.scss";

function About() {

  document.title = "About | Stock NPV"
  return (
    <Container>
      <h2>About Stock NPV</h2>
      <p>Welcome to the site.</p>
      <p>
        NPV stands for "Net Present Value" and the objective of this site is to
        help our users make intelligent valuations of companies based on an
        understanding of the fundamentals of the company. I want you to build
        sense of clarity and confidence when it comes to investing.
      </p>
      <p>
        I've built this site as a way putting the tools and my way of thinking
        when it comes to the market online. This site will be ever evolving as I
        build new insights and make the tools available for people to use. In
        the end, I want you, as the visitor to come away from this site with an
        understsanding of the fundamentals of a company and not be swayed by
        hype or hyperbole from other financial sites by using the tools I
        provide here for you to come to your own conculusions. I have a saying
        where everyone should come to the same conclusion given the same input
        and the same process. Not everyone's processes and assumtions will
        necessairly be the same but I do want to encourage everyne to build your
        own though processes when you evaluate the metrics of a company.
      </p>
      <p>
        I present calculations as per-share values or in normalized ratios and
        compare those with the share value of a company -- This hopefully puts
        into perspective of the income, balances and cashflow of the company
        relative to its share price.
      </p>
    </Container>
  );
}

export default About;
