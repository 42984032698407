import React, { useState } from "react";
import DynamicForm from "../components/FormGenerator";
import { Container, Alert } from "reactstrap";
import { CONTACT_URL } from "../app/endpoints";

export default function ContactUs() {
  document.title = "Contact Us | StockNPV";
  const [disabled, updateDisabled] = useState(false);
  const fields = [
    { type: "FormEmail", name: "email", label: "Email" },
    { type: "FormText", name: "subject", label: "Subject" },
    { type: "FormTextArea", name: "comment", label: "Comment" },
  ];

  const onSubmit = function (data) {
    console.log(data);
    document.getElementById("contact-us").reset();
  };

  const updateFormHook = function () {
    updateDisabled(true);
  };

  return (
    <>
      <Container>
        <h2>Contact Us</h2>
        <p>
          Thanks for taking an interest on this site. We'd be happy to hear
          about your feedback or comments about this site!
        </p>
        <p>
          We value and respect your privacy and will not provide your email
          address to 3rd parties.
        </p>
        <DynamicForm
          id="contact-us"
          fields={fields}
          url={CONTACT_URL}
          onSubmit={onSubmit}
          disabled={disabled}
          updateFormHook={updateFormHook}
        />
      </Container>
      {disabled && <Container className="mt-4">
        <Alert color="success">Thank you! Your feedback has been sent.</Alert>
      </Container>}
    </>
  );
}
